<template>
  <div
    id="template-proposal"
    class="proposal"
  >
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      type="proposal"
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :template="true"
      :redirect="redirectPath"
    />
    <v-add-tax-modal
      :show.sync="isShowTaxModal"
      @close="closeTaxModal"
      @saved="savedTaxModal"
    />

    <v-video-modal
      name="overview"
      title="Proposal template"
      description="A video to help you get started."
      step="proposal-template"
      thumbnail="templates.png"
      embed="EvsUFO3fICY"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <div>
      <div class="flex">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full"
        >
          <div class="bg-grey-black px-4 mt-16">
            <div class="max-w-xl mx-auto text-white pt-6">
              <div class="flex">
                <div class="w-full mb-5 md:mb-0">
                  <div class="flex items-center  mb-5">
                    <div class="w-3/4">
                      <h1 class="text-xl text-white">
                        Template Proposal options
                      </h1>
                    </div>
                    <div class="block md:hidden w-1/3 text-right">
                      <div class="inline-block">
                        <v-tutorial-button
                          mode="dark"
                          @click="showTutorialVideoModal"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="input-group">
                    <input
                      v-model="name"
                      type="text"
                      class="w-full"
                      name="name"
                      placeholder="Name of the template proposal"
                    >
                  </div>

                  <div class="my-5">
                    <div
                      class="options-item flex flex-wrap items-center"
                    >
                      <div class="select-container mr-5">
                        <select v-model="currency">
                          <option
                            v-for="cur in currencies"
                            :key="cur.id"
                            :value="cur"
                          >
                            {{ cur.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        v-if="hasTaxes"
                        class="inline-block mt-3 sm:mt-0 mr-5"
                      >
                        <v-checkbox
                          id="isTaxEnable"
                          v-model="isTaxEnable"
                          :checked="isTaxEnable"
                          type="dark"
                          label="Apply tax"
                        />
                        <div
                          v-show="isTaxEnable"
                          class="inline-block ml-8 mr-5"
                        >
                          <v-row class="items-center">
                            <v-col>
                              <div class="select-container">
                                <select v-model="isTaxPriceIncluded">
                                  <option
                                    :value="true"
                                  >
                                    Tax included
                                  </option>
                                  <option
                                    :value="false"
                                  >
                                    Tax excluded
                                  </option>
                                </select>
                              </div>
                              <v-help-tooltip
                                :options="tooltipContent"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else
                        class="inline-block"
                      >
                        <a
                          class="cursor-pointer text-green underline mr-5"
                          @click="showTaxModal"
                        >
                          Add a tax class
                        </a>
                      </div>
                      <div class="inline-block mt-3 mb-3 md:my-0 mr-8">
                        <span class="text-blue-washed-grey">Valid period</span>
                        <div class="select-container ml-5">
                          <select v-model="validPeriod">
                            <option value="14">
                              14 days
                            </option>
                            <option value="30">
                              30 days
                            </option>
                            <option value="60">
                              60 days
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="options-item flex flex-wrap items-center"
                    >
                      <div>
                        <v-checkbox
                          id="isInvoiceGenerated"
                          v-model="isInvoiceGenerated"
                          :checked="isInvoiceGenerated"
                          type="dark"
                          label="Generate an invoice upon signing of this quote"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Steps -->
            <!-- <div class="steps mt-8 max-w-xl mx-auto">
              <div class="flex px-2 justify-between">
                <div :class="{'active' : step == 1}">
                  <span class="step">1</span>
                  Proposal
                </div>
                <div :class="{'active' : step == 2, 'disable' : !isPaymentScheduleActive}">
                  <span class="step">2</span>
                  Payment plan
                </div>
                <div :class="{'active' : step == 3, 'disable' : !isContractIncluded}">
                  <span class="step">3</span>
                  Contract
                </div>
                <div>
                  &nbsp;
                </div>
              </div>
            </div> -->

            <div class="steps mt-20 md:mt-12 lg:mt-8 max-w-xl mx-auto overflow-hidden">
              <div class="flex items-center justify-between">
                <div class="w-full md:w-10/12 justify-between md:justify-start flex">
                  <div
                    class="w-1/3 md:w-auto"
                    :class="{'active' : step == 1}"
                  >
                    <v-checkbox-tab
                      id="proposal"
                      v-model="hasProposal"
                      active="false"
                      label="Proposal"
                      item-step="1"
                      :current-step.sync="step"
                      @switchStep="switchStep(1)"
                      @activeTab="activeTab(1)"
                    />
                  </div>
                  <div
                    class="w-1/3 md:w-auto"
                    :class="{'active' : step == 2, 'disable' : !isPaymentScheduleActive}"
                  >
                    <v-checkbox-tab
                      id="isPaymentScheduleActive"
                      v-model="isPaymentScheduleActive"
                      label="Payment"
                      class="px-2"
                      item-step="2"
                      :current-step.sync="step"
                      @change.native="calculateSteps(2)"
                      @switchStep="switchStep(2)"
                      @activeTab="activeTab(2)"
                    />
                  </div>
                  <div
                    class="w-1/3 md:w-auto"
                    :class="{'active' : step == 3, 'disable' : !isContractIncluded}"
                  >
                    <v-checkbox-tab
                      id="isContractIncluded"
                      v-model="isContractIncluded"
                      label="Contract"
                      item-step="3"
                      :current-step.sync="step"
                      @change.native="calculateSteps(3)"
                      @switchStep="switchStep(3)"
                      @activeTab="activeTab(3)"
                    />
                  </div>
                </div>
                <div class="pb-2 hidden md:block">
                  <v-tutorial-button
                    mode="dark"
                    @click="showTutorialVideoModal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="step == 1"
            id="step-1"
            class="mt-10"
          >
            <div class="proposal-form bg-white max-w-xl mx-auto shadow py-8">
              <div class="w-full px-8">
                <h3>
                  Deliverables
                </h3>
                <p class="note">
                  Items that are included in the invoice, that a client has is not able to select.
                </p>
                <div class="note mb-3">
                  <strong>Tip</strong>: You can use tokens like <strong>@clientFirstName</strong> here as well. <a href="https://intercom.help/octoa/en/articles/7135447-using-tokens-in-the-octoa-tool" target="_blank">Learn more</a>.
                </div>
              </div>
              <div class="heading">
                <div class="flex px-8 w-full font-bold">
                  <div class="w-1/2">
                    Item
                  </div>
                  <div class="w-1/2 px-2 hidden md:flex flex-grow">
                    <div class="pr-2">
                      Quantity
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-32 pl-2 pr-8"
                    >
                      Tax
                    </div>
                    <div class="px-2">
                      Price
                    </div>
                    <div class="pl-2 w-full text-right">
                      Total
                    </div>
                  </div>
                </div>
              </div>

              <!-- Required list items -->
              <div class="px-4">
                <!-- Draggable -->
                <draggable
                  :list="listofItems"
                  handle=".drag"
                  drag-class="drag-helper"
                  animation="200"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <div
                      v-for="(item, index) in listofItems"
                      :key="item.id"
                    >
                      <div class="draggable-item">
                        <div class="drag">
                          <div class="bg">
                            <img
                              src="@/assets/img/icons/scroll.svg"
                              width="15"
                            >
                          </div>
                        </div>
                        <div
                          class="delete"
                          @click="removeItem(index)"
                        >
                          <div class="bg">
                            <img
                              src="@/assets/img/icons/delete.svg"
                              width="25"
                            >
                          </div>
                        </div>
                        <div
                          class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                          style="background:transparent;"
                        >
                          <div class="w-full md:w-1/2 px-2">
                            <vue-autosuggest
                              :ref="'autoSuggestId'+item.id"
                              :suggestions="suggestions"
                              :input-props="{id:'autosuggest__input',
                                             initialValue: item.name,
                                             onInputChange: autoSuggestOnInputChange,
                                             autocomplete: 'off',
                                             placeholder:'Item name'}"
                              @blur="autoSuggestBlurHandler(item)"
                              @click="autoSuggestClickHandler(item)"
                              @selected="autoSuggestSelectHandler"
                            >
                              <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                              </template>
                            </vue-autosuggest>
                            <textarea-autosize
                              v-model="item.description"
                              placeholder="Item description"
                              class="md:hidden block mt-3 mb-3"
                              :min-height="100"
                              :max-height="300"
                            />
                          </div>

                          <div class="w-1/2 hidden md:flex justify-end ">
                            <div class="px-2">
                              <input
                                v-model="item.quantity"
                                type="number"
                                min="0"
                                class="quantity"
                                @input="checkQuantity(item, $event)"
                              >
                            </div>
                            <div
                              v-if="isTaxEnable"
                              class="px-2"
                            >
                              <div class="select-container">
                                <select v-model="item.tax">
                                  <option
                                    v-for="tax in taxes"
                                    :key="tax.id"
                                    :value="tax.value"
                                    :selected="(item.tax == tax.value) ? 'selected' : ''"
                                  >
                                    {{ tax.value }}%
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="px-2 w-36">
                              <vue-numeric
                                v-model="item.price"
                                :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                :precision="2"
                                class="small-right"
                                :currency="currency.sign"
                                :minus="true"
                                :decimal-separator="currency.decimal_separator"
                                :thousand-separator="currency.thousand_separator"
                              />
                            </div>
                            <div class="px-2 flex flex-grow">
                              <div class="mt-3 pr-2 w-full text-right break-words">
                                <vue-numeric
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  read-only
                                  read-only-class="font-bold"
                                  :currency="currency.sign"
                                  :minus="false"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                  :min="0"
                                  :value="calculateUnitPrice(item)"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- Mobile -->
                          <div class="flex flex-wrap md:hidden justify-between w-full pb-5 px-2">
                            <div class="w-1/2 mb-3 pr-3">
                              <div class="font-bold mb-1">
                                Quantity
                              </div>
                              <input
                                v-model="item.quantity"
                                type="number"
                                min="0"
                                class="quantity"
                                name="quantity"
                                @input="checkQuantity(item, $event)"
                              >
                            </div>
                            <div
                              v-if="isTaxEnable"
                              class="w-1/2 "
                            >
                              <div class="font-bold mb-1">
                                Tax
                              </div>
                              <div class="select-container">
                                <select v-model="item.tax">
                                  <option
                                    v-for="tax in taxes"
                                    :key="tax.id"
                                    :value="tax.value"
                                    :selected="(item.tax == tax.value) ? 'selected' : ''"
                                  >
                                    {{ tax.value }}%
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="w-1/2"
                              :class="{'pr-3' : isTaxEnable}"
                            >
                              <div class="font-bold mb-1">
                                Price
                              </div>
                              <vue-numeric
                                v-model="item.price"
                                :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                :precision="2"
                                class="small-right"
                                :currency="currency.sign"
                                :minus="true"
                                :decimal-separator="currency.decimal_separator"
                                :thousand-separator="currency.thousand_separator"
                              />
                            </div>
                            <div :class="{ 'w-full' : !isTaxEnable, 'w-1/2' : isTaxEnable}">
                              <div class="font-bold mb-1">
                                Total
                              </div>
                              <div class="mt-4">
                                <vue-numeric
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  read-only
                                  :currency="currency.sign"
                                  :minus="false"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                  :min="0"
                                  :value="calculateUnitPrice(item)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex px-4">
                          <div class="w-4/5 md:w-full">
                            <textarea-autosize
                              v-model="item.description"
                              placeholder="Item description"
                              class="hidden md:block mt-3 mb-3"
                              :style="[isTaxEnable ? {'max-width': '795px'} : {'max-width': '710px'}]"
                              :min-height="100"
                              :max-height="300"
                            />
                            <label class="mr-5 pt-2 py-3 inline-block text-grey-dark">
                              <v-checkbox
                                :id="`item${item.id}-isTemplate`"
                                v-model="item.isTemplate"
                                classes="small"
                                label="Save item for future use"
                              />
                            </label>
                            <label class="pt-2 py-3 inline-block text-grey-dark">
                              <v-checkbox
                                :id="`item${item.id}-is_quantity_selectable`"
                                v-model="item.is_quantity_selectable"
                                classes="small"
                                label="Allow client to pick a quantity"
                              />
                            </label>
                          </div>
                          <div
                            class="block md:hidden w-1/5 text-right"
                            @click="removeItem(index)"
                          >
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="mt-3 mx-4">
                          <hr>
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
                <!-- End Draggable -->
              </div>

              <!-- Sub total -->
              <div class="flex flex-wrap px-8 mt-5 pb-3">
                <div class="w-full md:w-3/5">
                  <a
                    class="underline green text-sm mt-3 mb-5 cursor-pointer"
                    @click="addItem"
                  >
                    + Add new item
                  </a>
                </div>
                <div class="mt-5 md:mt-0 w-full md:w-2/5 flex flex-wrap ">
                  <div class="w-1/2 text-grey-darker">
                    Subtotal
                  </div>
                  <div class="w-1/2 text-right font-bold">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="subTotal"
                    />
                  </div>
                  <hr class="mt-5">
                </div>
              </div>

              <!-- Additional options -->
              <div class="pt-8">
                <div class="px-8">
                  <div class="flex mb-3 flex-wrap">
                    <h3 class="w-full">
                      Optional item(s)
                    </h3>
                    <p class="note mb-3">
                      Items that a client can choose to add to the proposal.
                    </p>
                  </div>
                </div>
                <hr>
                <div
                  v-if="listofAdditonalItems.length > 0"
                  class="heading"
                >
                  <div class="flex px-8 w-full font-bold">
                    <div class="w-1/2">
                      Item
                    </div>
                    <div class="w-1/2 px-2 hidden md:flex flex-grow">
                      <div class="pr-2">
                        Quantity
                      </div>
                      <div
                        v-if="isTaxEnable"
                        class="w-32 pl-2 pr-8"
                      >
                        Tax
                      </div>
                      <div class="px-2">
                        Price
                      </div>
                      <div class="pl-2 w-full text-right">
                        Total
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Additional list items -->
                <div class="px-4">
                  <!-- Draggable -->
                  <draggable
                    :list="listofAdditonalItems"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofAdditonalItems"
                        :key="item.id"
                      >
                        <div class="draggable-item">
                          <div class="drag">
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/scroll.svg"
                                width="15"
                              >
                            </div>
                          </div>
                          <div
                            class="delete"
                            @click="removeAdditionalItem(index)"
                          >
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                          <div
                            class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                            style="background:transparent;"
                          >
                            <div class="w-full md:w-1/2 px-2">
                              <vue-autosuggest
                                :ref="'autoSuggestId'+item.id"
                                :suggestions="suggestions"
                                :input-props="{id:'autosuggest__input',
                                               initialValue: item.name,
                                               onInputChange: autoSuggestOnInputChange,
                                               autocomplete: 'off',
                                               placeholder:'Item name'}"
                                @blur="autoSuggestAdditionalBlurHandler(item)"
                                @click="autoSuggestClickHandler(item)"
                                @selected="autoSuggestAdditionalSelectHandler"
                              >
                                <template slot-scope="{suggestion}">
                                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                </template>
                              </vue-autosuggest>

                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="md:hidden block mt-3 mb-3"
                                :min-height="100"
                                :max-height="300"
                              />
                            </div>

                            <div class="w-1/2 hidden md:flex justify-end ">
                              <div class="px-2">
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="px-2"
                              >
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="tax.value"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="px-2 w-36">
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div class="px-2 flex flex-grow">
                                <div class="mt-3 pr-2 w-full text-right break-words">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    read-only-class="font-bold"
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- Mobile -->
                            <div class="flex flex-wrap md:hidden justify-between w-full pb-5 px-2">
                              <div class="w-1/2 mb-3 pr-3">
                                <div class="font-bold mb-1">
                                  Quantity
                                </div>
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="w-1/2 "
                              >
                                <div class="font-bold mb-1">
                                  Tax
                                </div>
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="tax.value"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="w-1/2"
                                :class="{'pr-3' : isTaxEnable}"
                              >
                                <div class="font-bold mb-1">
                                  Price
                                </div>
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div :class="{ 'w-full' : !isTaxEnable, 'w-1/2' : isTaxEnable}">
                                <div class="font-bold mb-1">
                                  Total
                                </div>
                                <div class="mt-4">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex px-4 -mt-4">
                            <div class="w-4/5 md:w-full">
                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="block mt-6 mb-3"
                                :style="[isTaxEnable ? {'max-width': '795px'} : {'max-width': '710px'}]"
                                :min-height="100"
                                :max-height="300"
                              />
                              <label class="mr-5 pt-2 py-3 inline-block text-grey-dark">
                                <v-checkbox
                                  :id="`item${item.id}-isTemplate`"
                                  v-model="item.isTemplate"
                                  classes="small"
                                  label="Save item for future use"
                                />
                              </label>
                              <label class="pt-2 py-3 inline-block text-grey-dark">
                                <v-checkbox
                                  :id="`item${item.id}-is_quantity_selectable`"
                                  v-model="item.is_quantity_selectable"
                                  classes="small"
                                  label="Allow client to pick a quantity"
                                />
                              </label>
                            </div>
                            <div
                              class="block md:hidden w-1/5 text-right"
                              @click="removeAdditionalItem(index)"
                            >
                              <div class="bg">
                                <img
                                  src="@/assets/img/icons/delete.svg"
                                  width="25"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 mx-4">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <!-- End Draggable -->
                </div>

                <!-- Sub total -->
                <div class="flex flex-wrap px-8 mt-10 pb-3">
                  <div class="w-full md:w-3/5">
                    <a
                      class="underline green text-sm mt-3 mb-5 cursor-pointer"
                      @click="addAdditionalItem"
                    >
                      + Add an optional item
                    </a>
                  </div>
                  <div class="w-full mt-8 md:mt-0 md:w-2/5 text-base flex items-center flex-wrap ">
                    <div
                      class="w-1/2 "
                    >
                      Discount
                    </div>
                    <div
                      class="w-1/2 items-center text-right"
                    >
                      <vue-numeric
                        v-model="discount"
                        :precision="2"
                        class="small-right"
                        :minus="false"
                        :min="0"
                      /> &nbsp; %
                    </div>
                    <hr
                      class="my-5"
                    >
                    <div class="w-1/2">
                      Subtotal <span v-if="isTaxEnable && !isTaxPriceIncluded">(excl. tax)</span>
                    </div>
                    <div class="w-1/2 text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="viewSubTotal"
                      />
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-full"
                    >
                      <div
                        v-for="(tax, key) in taxClasses"
                        :key="key"
                        class="w-full flex flex-wrap mt-5"
                      >
                        <hr
                          v-if="isTaxEnable"
                          class="mb-5"
                        >
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2"
                        >
                          Tax {{ key }}%
                        </div>
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2 text-right"
                        >
                          <vue-numeric
                            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                            :precision="2"
                            read-only
                            :currency="currency.sign"
                            :minus="false"
                            :decimal-separator="currency.decimal_separator"
                            :thousand-separator="currency.thousand_separator"
                            :min="0"
                            :value="tax"
                          />
                        </div>
                      </div>
                    </div>

                    <hr class="my-5 thick">
                    <div class="w-1/2 text-lg font-bold">
                      Total <span v-if="isTaxEnable && isTaxPriceIncluded">(incl. tax)</span>
                    </div>
                    <div class="w-1/2 text-lg font-bold text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="total"
                      />
                    </div>
                  </div>
                </div>

                <!-- Notes -->
                <div class="flex px-8 mt-10">
                  <textarea
                    v-model="notes"
                    placeholder="Additional notes"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="step == 2"
            id="step-2"
            class="mt-10 mx-4"
          >
            <div class="bg-white max-w-xl mx-auto shadow py-8">
              <div class="px-8">
                <div class="flex flex-wrap mb-3 ">
                  <h3 class="w-full">
                    Schedule payment
                  </h3>
                  <p class="note">
                    Allow customers to pay the total amount in multiple segments. It will be shown on the proposal and separate invoices can/will be generated, which have to be sent manually.
                  </p>
                </div>
              </div>

              <div class="px-2 heading">
                <div class="text-grey-darker flex px-4 w-full text-xs font-bold">
                  <div class="w-full md:w-2/12 px-2">
                    <span class="hidden md:block">Percentage</span>
                    <span class="block md:hidden">Item</span>
                  </div>
                  <div class="hidden md:block w-2/3 md:w-4/12 px-2">
                    Type
                  </div>
                  <div class="hidden md:block w-6/12 px-2">
                    When
                  </div>
                </div>
              </div>

              <!-- Payment item -->
              <div
                v-for="(item, index) in listofPaymentItems"
                :key="index"
                class="px-6"
              >
                <div
                  v-if="!item.is_delete"
                  class="flex flex-wrap paymentItem items-center py-5 mb-3 text-sm"
                >
                  <div class="w-2/3 md:w-2/12 px-2">
                    <div class="text-grey-darker font-bold text-xs mb-2 block md:hidden">
                      Percentage
                    </div>
                    <input
                      v-model="item.percentage"
                      type="number"
                      min="0"
                      max="100"
                      class="w-14"
                      @blur="blurPaymentItemPercent($event)"
                      @focus="$event.target.select()"
                    > &nbsp; %
                  </div>
                  <div class="w-full md:w-4/12 px-2 my-3 md:my-0">
                    <div class="text-grey-darker font-bold text-xs  mb-2 block md:hidden">
                      Type
                    </div>
                    <div class="select-container tall">
                      <select
                        v-model="item.type"
                        class="w-52"
                      >
                        <option value="on_receive">
                          Upon approval
                        </option>
                        <option value="on_completed">
                          Upon complete
                        </option>
                        <option value="project_date">
                          Project date
                        </option>
                        <!--  <option value="fixed_date">
                          Fixed date
                        </option> -->
                        <option value="custom_date">
                          Before / After project date
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-full md:w-6/12 px-2">
                    <div
                      v-show="item.type == 'on_receive'"
                      class="on-recieve"
                    >
                      As soon as proposal is accepted
                    </div>
                    <div
                      v-show="item.type == 'on_completed'"
                      class="on-recieve"
                    >
                      As soon as project is marked as finished
                    </div>
                    <div
                      v-show="item.type == 'project_date'"
                      class="project-date mt-1"
                    >
                      Date of the project
                    </div>
                    <!-- <div
                      v-show="item.type == 'fixed_date'"
                      class="fixed-date w-42"
                    >
                      <v-date-picker
                        v-model="item.date"
                        class="datepicker "
                        name="fixed_date"
                        :input-props='{
                          placeholder: "Fixed date",
                        }'
                        :popover="{ visibility: 'click' }"
                        :masks="{L: 'WWW, MMM D, YYYY'}"
                      />
                    </div> -->
                    <div
                      v-show="item.type == 'custom_date'"
                      class="custom-date"
                    >
                      <input
                        v-model="item.custom_length"
                        type="number"
                        min="1"
                        max="999"
                        class="w-8"
                      >
                      <div class="select-container tall ml-2">
                        <select v-model="item.custom_length_type">
                          <option value="days">
                            day(s)
                          </option>
                          <option value="weeks">
                            weeks(s)
                          </option>
                          <option value="months">
                            month(s)
                          </option>
                        </select>
                      </div>
                      <div class="select-container tall ml-2">
                        <select v-model="item.is_before_project_date">
                          <option value="1">
                            before project date
                          </option>
                          <option value="0">
                            after project date
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="pin-r pin-t mt-1 absolute cursor-pointer">
                      <img
                        src="@/assets/img/icons/delete.svg"
                        alt="Delete"
                        width="17"
                        @click="removePaymentItem(index)"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add -->
              <div class="flex px-8 mt-6">
                <div class="w-full">
                  <a
                    class="underline green text-sm mt-3 mb-5 cursor-pointer"
                    @click="addPaymentItem"
                  >
                    + Add another payment item
                  </a>
                </div>
              </div>
            </div>
          </div>


          <!-- Step 3 -->
          <div
            v-show="step == 3"
            id="step-3"
            class="mt-10 mx-4"
          >
            <div class="bg-white max-w-xl mx-auto shadow py-8">
              <div class="px-8">
                <div class="flex flex-wrap items-center">
                  <div class="w-full md:w-1/3">
                    <h3 class="w-full">
                      Contract
                    </h3>
                  </div>
                  <div class="mt-4 md:mt-0 w-full md:w-2/3 text-left md:text-right">
                    <div class="select-container border w-full md:w-3/4">
                      <select
                        v-model="selectedContractTemplate"
                        @change="changeContract"
                      >
                        <option value="0">
                          Select a contract template
                        </option>
                        <option
                          v-for="item in contracts"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="mt-5">
                  <v-ckeditor v-model="contractContent" />
                  <div class="mt-3">
                    <v-checkbox
                      id="isContractTemplate"
                      v-model="isContractTemplate"
                      classes="small"
                      label="Save as template"
                    />

                    <input
                      v-show="isContractTemplate"
                      id="contractName"
                      v-model="contractName"
                      class="mt-3 w-full"
                      type="text"
                      placeholder="Name of the contract"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mt-5 mb-10 max-w-xl px-4 pb-10 mx-auto text-right">
            <div class="w-full">
              <div class="inline-block mr-3">
                <!-- <a
                  class="green underline cursor-pointer"
                  @click="save"
                >
                  Save and edit later
                </a> -->
              </div>
              <div
                v-if="step != 1"
                class="inline-block mr-3"
              >
                <a
                  class="cursor-pointer green underline mr-3"
                  @click="previousStep"
                >
                  Back
                </a>
              </div>
              <button
                v-if="step != lastStep"
                class="green-btn px-10"
                @click="nextStep"
              >
                Next
              </button>
              <button
                v-if="step == lastStep"
                class="green-btn px-10"
                @click="save"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { applyDrag, trim2Decimals, calculateDiscountTotal, calculateEachTaxClassPrice, calculateTaxTotal } from '@/utils/helpers'
import { addDays, format } from 'date-fns'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
	data(){
  	return {
      hasProposal: true,
      metaTitle: 'Loading',
      hasTaxes: false,
      taxes: {},
      currency:{},
      isSaving: false,
      isSaved: false,
      loading: true,
      step:1,
      lastStep: 3,
      proposalId: null,
      name: '',
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      taxPercent: 21,
      validPeriod: 60,
      discount: 0,
      isContractIncluded: true,
      isInvoiceGenerated: true,
      isPaymentScheduleActive: true,
      selectedListId: null,
      suggestions: [],
      listofItems: [],
      listofAdditonalItems:[],
      listofPaymentItems: [],
      notes: '',
      isContractTemplate: false,
      contracts: {},
      selectedContractTemplate: 0,
      contractId: 0,
      contractName: '',
      contractContent: '',
      user:auth.user(),
      tooltipContent: {
        content: `
          *Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the price.
        `
      },
      redirectPath: '/templates/proposals',
      editorUpdatePending: false,
      isShowTaxModal: false,
      isShowTutorialVideoModal: false,
      currencies: null,
    }
  },
  computed: {
    subTotal(){
      return this.listofItems.reduce(function (total, item) {
        return total + item.price*item.quantity
      }, 0)
    },
    discountTotal(){
      let total = 0
      if(this.subTotal > 0){
        total = this.subTotal * (this.discount/100)
      }
      return total
    },
    discountedTotal(){
      let total = this.subTotal
      if(this.discountTotal > 0){
        total = this.subTotal - this.discountTotal
      }

      return total
    },
    taxClasses(){
      let results = calculateEachTaxClassPrice(this.listofItems, this.subTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    taxTotal(){
      let results = calculateTaxTotal(this.listofItems, this.subTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    viewSubTotal(){
      let originalSubTotal = this.listofItems.reduce(function (total, item) {
        return total + (item.price*item.quantity)
      }, 0)

      let discountTotal = calculateDiscountTotal(this.listofItems, this.discount, this.discountTotal, this.isTaxEnable, this.isTaxPriceIncluded)
      let subTotal = originalSubTotal - discountTotal

      if(this.isTaxEnable && this.isTaxPriceIncluded){
        subTotal = (originalSubTotal - this.discountTotal)
        let value =  parseFloat(subTotal - this.taxTotal)

        return trim2Decimals(value)
      }

      return trim2Decimals(subTotal)
    },
    total(){
      let total = 0

      total = this.subTotal - this.discountTotal

      if(this.isTaxEnable && !this.isTaxPriceIncluded){
        total += Number(this.taxTotal)
      }

      return total
    }
  },
  async mounted(){

    // Load settings
    this.currency = this.user.companySettings.currency
    this.taxes = this.user.companySettings.taxes

    if(this.taxes){
      if(this.taxes[0] !== undefined){
        this.hasTaxes = true
        this.taxPercent = this.taxes[0].value
      }
    }

    this.isTaxPriceIncluded = (this.user.companySettings.includeTaxInPrice == 0) ? false : true
    this.validUntil = addDays(new Date(), this.user.companySettings.proposalValidPeriod)

    try {
      const { data } = await this.$api.get('currency').list()
      this.currencies = data.currencies
    } catch(e){
      this.$toasted.global.api_error(e)
      logException(e)
    }

    await this.getContractTemplates()

    if(this.$route.params.templateId){
      await this.getProposal(this.$route.params.templateId)
      this.metaTitle = `Template Proposal: ${this.name}`
    } else {
      this.metaTitle = 'New Template Proposal'
      //this.addItem()
      this.addPaymentItem()
    }

    this.loading = false

    if(this.$route.query.project){
      this.redirectPath = `/projects/${this.$route.query.project}/proposal/choose`
    }
  },
	methods: {
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    dateFormatter(value) {
      return format(value, 'MMM Do, YYYY')
    },
    dateFormatSave(value) {
      return format(value, 'YYYY-MM-DD')
    },
    calculateUnitPrice(item){
      return item.price*item.quantity
    },
    autoSuggestBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    autoSuggestAdditionalBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofAdditonalItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    async autoSuggestOnInputChange(text, oldText){

      if (text === null || text == '' || oldText === null || oldText == '') {
        /* Maybe the text is null but you wanna do
        *  something else, but don't filter by null.
        */
        return
      }
      let that = this

      try {
        const postData = {
          companyId: this.user.company.id,
          name: text,
        }
        const { data } = await this.$api.get('templateProduct').search(postData)
        that.suggestions = [{ data: data.results }]

      } catch(e){
        logException(e)
      }
    },
    autoSuggestClickHandler(item) {
      this.selectedListId = item.id
    },
    autoSuggestSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.is_quantity_selectable = suggestedItem.is_quantity_selectable
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.tax = suggestedItem.tax
        }
      })
    },
    autoSuggestAdditionalSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofAdditonalItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.is_quantity_selectable = suggestedItem.is_quantity_selectable
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.tax = suggestedItem.tax
        }
      })
    },
    addAdditionalItem() {
      this.listofAdditonalItems.push({
          id: new Date().getTime(),
          name: '',
          description: '',
          quantity: 1,
          price: 0,
          isTaxEnable: this.isTaxEnable,
          isTemplate: false,
          is_quantity_selectable: 0,
          tax: this.taxPercent,
          isHover:false,
      })
    },
    removeAdditionalItem(index){
      if(this.listofAdditonalItems.length > 0) {
        this.listofAdditonalItems.splice(index, 1)
      }
    },
    addItem() {
      this.listofItems.push({
        id: new Date().getTime(),
        name: '',
        description: '',
        quantity: 1,
        price: 0,
        isTaxEnable: this.isTaxEnable,
        isTemplate: false,
        is_quantity_selectable: 0,
        tax: this.taxPercent,
        isHover:false,
      })
    },
    removeItem(index){
      if(this.listofItems.length > 0) {
        this.listofItems.splice(index, 1)
      }
    },
    switchStep(step){
      this.step = step
    },
    activeTab(step){

      let switchStep = false
      if(step == 2 && !this.isPaymentScheduleActive){
        this.isPaymentScheduleActive = true
        switchStep = true

        if(this.listofPaymentItems.length == 0){
          this.addPaymentItem()
        }
      }
      if(step == 3 && !this.isContractIncluded){
        this.isContractIncluded = true
        switchStep = true
      }

      if(switchStep){
        this.switchStep(step)
      }
      this.calculateSteps(step)
    },
    previousStep(){

      if(!this.isPaymentScheduleActive) {
        this.step--
      }
      this.step--

      if(this.step < 1){
        this.$router.push(`/projects/${this.$route.params.id}`)
      }
    },
    nextStep(){

      let requireListItemsLength = this.listofItems.length
      if(this.step == 1 && requireListItemsLength == 0){
        this.$toasted.global.general_error({
          message : 'Please add at least one item to continue.'
        })

        return false
      }

      if(this.step == 1 && this.name == ''){
        this.$toasted.global.general_error({
          message : 'Please fill in a proposal name.'
        })

        return false
      }

      if(!this.isPaymentScheduleActive) {
        this.step++
      }

      this.step++
      this.calculateSteps(this.step)
    },
    calculateSteps(step){
      // No contract and no payment schedule
      if(!this.isContractIncluded && !this.isPaymentScheduleActive){
        this.lastStep = 1
        if(this.step > 1){
          this.step = 1
          return
        }
      }

      // Have contract and no payment schedule
      if(this.isContractIncluded && !this.isPaymentScheduleActive){
        this.lastStep = 3
        if(this.step == 2){
          this.step = 1
          return
        }
      }

      // No contract and have payment schedule
      if(!this.isContractIncluded && this.isPaymentScheduleActive){
        this.lastStep = 2
        if(this.step > 2){
          this.step = 2
          return
        }
      }

      if(this.isContractIncluded){
        this.lastStep = 3
      }

      if(step == 2 && this.isPaymentScheduleActive){
        this.step = 2
      }

      if(step == 3 && this.isContractIncluded){
        this.step = 3
      }
    },
    save() {
      if(this.name == ''){
        this.$toasted.global.general_error({
          message : 'Please fill in a template name.'
        })
        return false
      }

      let that = this
      let totalPercentage = 0
      this.listofPaymentItems.forEach(function(item) {
        totalPercentage += Number(item.percentage)
      })

      if(totalPercentage != 100 && this.isPaymentScheduleActive){
        this.$toasted.global.general_error({
          message : 'Payment schedule total percentage should equal to 100%.'
        })
        return false
      }

      if(this.total < 0){
        this.$toasted.global.general_error({
          message : 'Total cannot be less than 0.'
        })
        return false
      }

      this.doSave()
    },
		async doSave() {

      this.saving = true
      let that = this

      try {
        const postData = {
          proposalId: this.proposalId,
          data: this._data,
          currencyId: this.currency.id,
          name: this.name,
          validPeriod: this.validPeriod,
          companyId: this.user.company.id,
          taxPercent: this.taxPercent,
          subTotal: this.subTotal,
          taxTotal: this.taxTotal,
          discountTotal: this.discountTotal,
          total: this.total,
        }

        const { data } = await this.$api.get('templateProposal').updateOrCreate(postData)

        this.proposalId = data.proposal.id

        this.listofPaymentItems = []
        data.listofPaymentItems.forEach(function(item) {
          item.is_delete = false
          that.listofPaymentItems.push(item)
        })

        this.isSaved = true
        this.saving = false

        await this.getProposal(this.proposalId)
        this.$toasted.global.general_success({
          message : 'Proposal template updated.'
        })

      } catch(e){
        logException(e)
      }
		},
    changeView(view){
      this.view = view
    },
    async getProposal(id){

      let that = this

      try {
        const { data } = await this.$api.get('templateProposal').detail(this.user.company.id, id)

          let proposal = data.proposal

          this.currency = proposal.currency
          this.proposalId = proposal.id
          this.name = proposal.name
          this.validPeriod = proposal.valid_period
          this.isTaxEnable = proposal.is_tax_enable
          this.isTaxPriceIncluded = proposal.is_tax_price_included
          this.taxPercent = proposal.tax
          this.discount = proposal.discount
          this.isContractIncluded = proposal.has_contract
          this.isInvoiceGenerated = proposal.has_invoice
          this.isPaymentScheduleActive = proposal.has_payment_schedules
          this.notes = proposal.notes

          // If tax happens to be deleted reset
          if(!this.hasTaxes){
            this.isTaxEnable = false
          }

          let that = this
          this.listofAdditonalItems = []
          this.listofItems = []
          proposal.items.forEach(function(item) {
            item.isTaxEnable = (!that.hasTaxes) ? false : item.is_taxable
            if(item.is_optional){
              that.listofAdditonalItems.push(item)
            } else {
              that.listofItems.push(item)
            }
          })

          if(proposal.payment_schedules){
            this.listofPaymentItems = []
            proposal.payment_schedules.forEach(function(item) {

              item.is_delete = false
              item.date = that.dateFormatSave(item.date)

              that.listofPaymentItems.push(item)
            })
          }

          if(proposal.contract){
            this.contractId = proposal.contract.id
            this.contractContent = proposal.contract.content
          }

      } catch(e){
        this.$router.push('/templates/proposals')
        logException(e)
      }

    },
    addPaymentItem() {

      let percentage = 0
      let totalPaymentItems = 0

      this.listofPaymentItems.push({
          id: new Date().getTime(),
          percentage: percentage,
          type: 'on_receive',
          date: this.dateFormatSave(new Date()),
          custom_length: 1,
          custom_length_type: 'days',
          is_before_project_date: 1,
          is_delete: false,
      })

      this.listofPaymentItems.forEach(function(item) {
          if(!item.is_delete){
            totalPaymentItems++
          }
      })

      this.listofPaymentItems.forEach(function(item) {
          if(!item.is_delete){
            let itemPercentage = (100 / totalPaymentItems)
            let number = parseFloat(Math.round((itemPercentage + Number.EPSILON) * 100) / 100)

            item.percentage = (number % 1 != 0) ? number.toFixed(2) : number
            percentage += item.percentage
          }
      })

      if(percentage < 0 || percentage == 0){
        this.$toasted.global.general_error({
          message : 'Payment schedule already exceed 100%.'
        })
        return false
      }
    },
    removePaymentItem(index){
      if(this.listofPaymentItems.length > 0) {
        this.listofPaymentItems[index].percentage = 0
        this.listofPaymentItems[index].is_delete = true
      }
    },
    blurPaymentItemPercent(e){
      if(isNaN(e.target.value)){
        e.target.value = 0
      }
    },
    async getContractTemplates(){
      try {
        const { data } = await this.$api.get('templateContract').list(this.user.company.id)
        this.contracts = data.contracts
      } catch(e){
        logException(e)
      }
    },
    changeContract(){
      if(this.selectedContractTemplate > 0){
        let contract = this.contracts.find(i => i.id === this.selectedContractTemplate)
        this.contractId = contract.id
        this.contractName = contract.name
        this.contractContent = contract.content

        this.editorUpdatePending = true
      }
    },
    showTaxModal(){
      this.isShowTaxModal = true
    },
    closeTaxModal(){
      this.isShowTaxModal = false
    },
    async savedTaxModal(taxes, defaultTaxPercent){
      this.isShowTaxModal = false
      this.isTaxEnable = true
      this.taxes = taxes
      this.hasTaxes = true
      this.taxPercent = defaultTaxPercent
      this.$modal.hide('addTaxModal')
    },
    checkQuantity(item, $event){
      let value = $event.target.value
      if(value < 0){
        item.quantity = 0
      }
      if(value > 1000){
        item.quantity = 1000
      }
    },
	},
}
</script>
